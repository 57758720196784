import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
    <header className="App-header">
      <h1 className="App-title">PaystackMafia</h1>
    </header>
    <main className="App-content">
      <p className="App-intro">Explore startups, operators and entrepreneurs building amazing things after Paystack.</p>
    </main>
    <footer className="App-footer">
      <p>Made with <span className="App-heart">&hearts;</span> by the Paystack Mafia</p>
    </footer>
  </div>
  );
}

export default App;
